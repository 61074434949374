<template>
  <div
    class="flex flex-col justify-center items-center w-full"
    :class="whiteSpace ? 'h-3/4-screen' : ''"
  >
    <div class="pulse mb-8">
      <img src="/images/icons/Loading.svg" alt="Loading icon" lazy="loading" />
    </div>
    <div>{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    whiteSpace: { type: Boolean, default: true },
    text: { String, default: 'Loading...' },
  },
};
</script>

<style scoped>
.pulse {
  animation: pulse 4s ease-in-out infinite;
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
</style>
