<!--
  This component is used for all pages that use pagination. 
  -->
<template>
  <!-- pagination -->
  <div
    class="bg-white p-3 flex items-center rounded-lg shadow my-2 justify-between border-t border-gray-200"
  >
    <div class="flex-1 flex justify-between sm:hidden">
      <button
        @click="setCurrentPage(previousPage)"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        :class="previousPage ? 'bg-white' : 'bg-gray-300'"
        :disabled="!previousPage"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <span class="">Previous</span>
      </button>

      <button
        @click="setCurrentPage(nextPage)"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        :class="nextPage ? 'bg-white' : 'bg-gray-300'"
        :disabled="!nextPage"
      >
        <span>Next</span>
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div class="hidden sm:inline-flex text-sm text-gray-700">
        <div
          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          view:
        </div>
        <button
          class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          v-for="l in selectableLimits"
          :key="l"
          :class="selectedLimit(l)"
          @click="setLimit(l)"
        >
          {{ l }}
        </button>
        <!-- <button
          class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          :class="selectedLimit(100)"
          @click="setLimit(100)"
        >
          100
        </button>

        <button
          class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          :class="selectedLimit(200)"
          @click="setLimit(200)"
        >
          200
        </button>
        <button
          class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          :class="selectedLimitFor500()"
          @click="setLimit(500)"
        >
          500
        </button>
        <button
          v-if="totalAvailable > 500"
          class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-r-md"
          :class="selectedLimit(1000)"
          @click="setLimit(1000)"
        >
          1000
        </button> -->
      </div>
      <div class="flex flex-col text-sm" v-if="admin && pageType === 'Order'">
        <div class="text-green-500">$ {{ revenue }}</div>
        <div class="text-gray-500">{{ selectedNumber }} orders</div>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <button
            @click="setCurrentPage(previousPage)"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50"
            :class="previousPage ? 'bg-white' : 'bg-gray-300'"
            :disabled="!previousPage"
          >
            <span class="sr-only">Previous</span>
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <!-- Current: "z-10 bg-blue-50 border-blue-500 text-blue-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <button
            v-if="totalPages > 2"
            @click="setCurrentPage(firstNumber)"
            class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            :class="isSelected(firstNumber)"
          >
            {{ firstNumber }}
          </button>
          <button
            @click="setCurrentPage(secondNumber)"
            class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            :class="isSelected(secondNumber)"
          >
            {{ secondNumber }}
          </button>
          <button
            v-if="totalPages > 1"
            @click="setCurrentPage(thirdNumber)"
            class="relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            :class="isSelected(thirdNumber)"
          >
            {{ thirdNumber }}
          </button>
          <button
            @click="setCurrentPage(nextPage)"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            :class="nextPage ? 'bg-white' : 'bg-gray-300'"
            :disabled="!nextPage"
          >
            <span class="sr-only">Next</span>
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
      <form
        @submit.prevent="goToPage(page)"
        class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
      >
        <div
          class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          go to page
        </div>
        <input
          v-model="page"
          class="bg-white w-10 text-center border-gray-300 text-gray-500 hover:bg-gray-50 px-1 relative inline-flex items-center py-2 border text-sm font-medium"
        />
        <button
          class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
          type="submit"
          :disabled="!page"
        >
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageType: String,
    admin: { type: Boolean, default: false },
    totalAvailable: Number,
    totalRevenue: [Number, String],
    selectedNumber: [Number, String],
    limit: [Number, String],
    currentPage: [Number, String],
    selectableLimits: Array,
  },
  data: () => {
    return {
      page: null,
    };
  },
  watch: {},
  computed: {
    firstNumber() {
      if (this.totalPages < 2) {
        return '...';
      }
      return 1;
    },
    secondNumber() {
      if (this.totalPages <= 2) {
        return 1;
      } else if (this.currentPage < this.totalPages && this.currentPage != this.firstNumber) {
        return this.currentPage;
      } else if (this.currentPage === this.totalPages) {
        return this.currentPage - 1;
      } else {
        return this.currentPage * 1 + 1;
      }
    },
    thirdNumber() {
      return this.totalPages;
    },
    totalPages() {
      const payload = this.totalAvailable;
      let val = payload / this.limit;

      if (val === 0) {
        return 1;
      }

      if (1 < val && val < 2) {
        return 2;
      }

      return Math.ceil(val);
    },
    previousPage() {
      if (this.currentPage > 1) {
        return this.currentPage * 1 - 1;
      }
      return null;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        return this.currentPage * 1 + 1;
      }
      return null;
    },
    revenue() {
      if (this.totalRevenue) {
        return new Intl.NumberFormat('en-IN', {
          minimumFractionDigits: 2,
        }).format(this.totalRevenue);
      }
      return null;
    },
  },
  methods: {
    setCurrentPage(payload) {
      this.page = null;
      this.$emit('setCurrentPage', payload);
    },
    setLimit(payload) {
      this.$emit('setLimit', payload);
    },
    isSelected(payload) {
      if (payload === this.currentPage) {
        return 'z-10 bg-blue-50 border-action text-action';
      }
      return 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50';
    },
    goToPage(payload) {
      payload = payload.replace(/\D/g, '');
      if (payload > this.totalPages) {
        this.setCurrentPage(this.totalPages);
      } else if (payload < 1) {
        this.setCurrentPage(1);
      } else {
        this.setCurrentPage(payload);
      }
    },
    selectedLimit(payload) {
      let returnClass = '';
      if (payload === this.limit) {
        returnClass += 'z-10 bg-blue-50 border-action text-action';
      } else {
        returnClass += 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50';
      }
      if (this.selectableLimits[this.selectableLimits.length - 1] == payload) {
        returnClass += ' rounded-r-md';
      }
      return returnClass;
    },
  },
};
</script>

<style></style>
