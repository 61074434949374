exports.status = (order) => {
  if (order.paid) {
    return 'text-green-500';
  }
  if (order.overdue) {
    return 'text-red';
  }
  if (order.status === 'Cancelled') {
    return 'text-orange-400';
  }
  if (order.status === 'Fulfilled') {
    return 'text-blue-500';
  }
  if (order.status === 'Pending') {
    return 'text-yellow-500';
  }
  return 'text-teal-500';
};

exports.border_top = (color) => {
  let text = 'border-t-8 rounded-l-xl ';
  switch (color) {
    case 'red':
      text += 'border-red-500';
      break;
    case 'green':
      text += 'border-green-500';
      break;
    case 'blue':
      text += 'border-blue-500';
      break;
    case 'purple':
      text += 'border-purple-500';
      break;
    case 'gray':
      text += 'border-gray-500';
      break;
  }
  return text;
};

exports.border = (color) => {
  let text = 'border ';
  switch (color) {
    case 'red':
      text += 'border-red-500';
      break;
    case 'green':
      text += 'border-green-500';
      break;
    case 'blue':
      text += 'border-blue-500';
      break;
    case 'purple':
      text += 'border-purple-500';
      break;
    case 'gray':
      text += 'border-gray-500';
      break;
  }
  return text;
};
